import { formatYYYYMMDD } from "../../utils";
import { makePOST, makePUT, makeREQUEST, tinyAlert, printError, } from "../_config.services";

class RoutingSlipForMaterialPurchaseFormService {
  // constructor() { }

  async setForm(data: any): Promise<any> {
    try{
      const form_approval_emails = data.form_approval.every((obj:any) => obj.email.trim() !== '');
      if (!form_approval_emails) {
          tinyAlert('error','Some or all emails in approval sequence are empty.');
          return;
      }

      const input = data.formInputs;
      const select = data.formSelects;
      let others_description = "Others";

      if(input.PONumber && input.PONumber.length < 10){
        tinyAlert('error','Contract Number must be equal to 10 numbers');
        return;
      }

      if(input.PRNumber && input.PRNumber.length < 8){
        tinyAlert('error','PR Number must be equal to 8 numbers');
        return;
      }

      const form: any = new FormData();
      form.append("BriefDescriptionOfMaterials", input.BriefDescriptionOfMaterials);
      form.append("PRNumber", input.PRNumber);
      form.append("Supplier", input.Supplier);
      form.append("Requisitioner", input.Requisitioner);
      form.append("POAmount", input.POAmount);
      form.append("PONumber", input.PONumber);
      form.append("DeliveryPlace", input.DeliveryPlace);
      form.append("POLocalContentValue", input.POLocalContentValue);
      form.append("POLocalContentCalculationMethod", input.POLocalContentCalculationMethod);
      form.append("POLocalContent", input.POLocalContent);
      form.append("SavingsValueUSD", Number(input.SavingsValueUSD));
      form.append("Addendum", input.Addendum);
      form.append("BuyersComment", input.BuyersComment);
      form.append("NameOfBuyer", input.NameOfBuyer);
      form.append("AlternateEmailAddress", input.AlternateEmailAddress);
      form.append("POCurrency", select.po_currency.value);
      form.append("DepartmentId", select.department.value);
      form.append("DDQStatus", select.ddq_status.value);
      form.append("Materialtype", select.material_type.value);
      form.append("DateOfPRAttribution", formatYYYYMMDD(select.date_of_prattribution));
      form.append("DateOfRFQ", formatYYYYMMDD(select.date_of_rfq));
      form.append("PlannedDelivery", formatYYYYMMDD(select.PlannedDelivery));
      form.append("NUPRCStatus", select.nuprc_status.value);
      form.append("ProcurementMethod", select.procurement_method.value);
      form.append("SavingType", select.saving_type.value);
      form.append("Incoterms", select.incoterms.value);
      form.append("LessThanThreeBidsInCFT", select.less_three_bids.value === 'Yes' ? true : false);
      form.append("SavingsAmount", Number(input.NewCost) - Number(input.ReferenceCost) );
      form.append("ReferenceCost", input.ReferenceCost);
      form.append("NewCost", input.NewCost);
      form.append("ContractJustification", input.ContractJustification);
      form.append("SavingsJustification", input.SavingsJustification);
      form.append("VendorsAggregateCommitmentYTDGreaterThan1M", select.VendorsAggregateCommitmentYTDGreaterThan1M.value === 'Yes' ? true : false);
      form.append("IsDraft", data.IsDraft);
      form.append("FormApprovers", JSON.stringify(data.form_approval));
      form.append("IsHumanRight", select.human_right.value);

      for (let i = 0; i < data.uploadedFiles.length; i++) {
        if (data.uploadedFilesAttachType[i] === "Signed RTA by Duet")
        form.append("SignedRtaByDuet", data.uploadedFiles[i]);

        if (data.uploadedFilesAttachType[i] === "Draft Contract/PO to be reviewed by Signatories")
        form.append("DraftContractPoToBeReviewedBySignatories", data.uploadedFiles[i]);

          if (data.uploadedFilesAttachType[i] === "Draft PO")
            form.append("DraftPO", data.uploadedFiles[i]);

          else if (data.uploadedFilesAttachType[i] === "Bid/Analysis Waiver")
            form.append("BidAnalysisWaiver", data.uploadedFiles[i]);

            else if (data.uploadedFilesAttachType[i] === "Bidders Lists")
              form.append("BiddersLists", data.uploadedFiles[i]);

          else if (data.uploadedFilesAttachType[i] === "Vendors Quotation")
            form.append("VendorsQuotation", data.uploadedFiles[i]);

            else if (data.uploadedFilesAttachType[i] === "Proof Of OEM Representation")
              form.append("ProofOfOEMRepresentation", data.uploadedFiles[i]);

              else if (data.uploadedFilesAttachType[i] === "Transaction Related Document")
                form.append("TransactionRelatedDocument", data.uploadedFiles[i]);

          else {
            form.append("Others", data.uploadedFiles[i]);
            others_description = data.uploadedFilesAttachType[i];
          }
      }

      form.append("OthersDescription", others_description);

      return await makePOST('RoutingSlipForMaterialPurchaseForm/Initiator/Submit', form)
      .then((res) => {
        tinyAlert("success", res.data.message);
        return res.data.data;
      })

    } catch (error:any) {
      console.error('Error2:', error);
      console.error('Error2:', JSON.stringify(error.response.data.errors));

      const allKeysRequired = !!error.response.data.errors ? Object.keys(error.response.data.errors).join(', ') : error.response.data.Message;
      tinyAlert("error", 'Complete all required field: '+ JSON.stringify(allKeysRequired) );
      
    }
  }

  async updateForm(data: any): Promise<any> {
    try{
      const input = data.formInputs;
      const select = data.formSelects;
      let others_description = "Others";

      console.log(input)

      
      if(input.PONumber && input.PONumber.length < 10){
        tinyAlert('error','PONumber must be equal to 10 numbers');
        return;
      }

      if(input.PRNumber && input.PRNumber.length < 8){
        tinyAlert('error','PR Number must be equal to 8 numbers');
        return;
      }

      const form: any = new FormData();
      form.append("BriefDescriptionOfMaterials", input.BriefDescriptionOfMaterials);
      form.append("PRNumber", input.PRNumber);
      form.append("Supplier", input.Supplier);
      form.append("Requisitioner", input.Requisitioner);
      form.append("POAmount", input.POAmount);
      form.append("PONumber", input.PONumber);
      form.append("DeliveryPlace", input.DeliveryPlace);
      form.append("POLocalContentValue", input.POLocalContentValue);
      form.append("POLocalContentCalculationMethod", input.POLocalContentCalculationMethod);
      form.append("POLocalContent", input.POLocalContent);
      form.append("SavingsValueUSD", Number(input.SavingsValueUSD));
      form.append("Addendum", input.Addendum);
      form.append("BuyersComment", input.BuyersComment);
      form.append("NameOfBuyer", input.NameOfBuyer);
      form.append("AlternateEmailAddress", input.AlternateEmailAddress);
      form.append("POCurrency", select.po_currency.value);
      form.append("DepartmentId", select.department.value);
      form.append("DDQStatus", select.ddq_status.value);
      form.append("Materialtype", select.material_type.value);
      form.append("DateOfPRAttribution", formatYYYYMMDD(select.date_of_prattribution));
      form.append("DateOfRFQ", formatYYYYMMDD(select.date_of_rfq));
      form.append("PlannedDelivery", formatYYYYMMDD(select.PlannedDelivery));
      form.append("NUPRCStatus", select.nuprc_status.value);
      form.append("ProcurementMethod", select.procurement_method.value);
      form.append("SavingType", select.saving_type.value);
      form.append("Incoterms", select.incoterms.value);
      form.append("SavingsAmount", Number(input.NewCost) - Number(input.ReferenceCost) );
      form.append("ReferenceCost", input.ReferenceCost);
      form.append("NewCost", input.NewCost);
      form.append("ContractJustification", input.ContractJustification);
      form.append("SavingsJustification", input.SavingsJustification);
      form.append("LessThanThreeBidsInCFT", select.less_three_bids.value === 'Yes' ? true : false);
      form.append("VendorsAggregateCommitmentYTDGreaterThan1M", select.VendorsAggregateCommitmentYTDGreaterThan1M.value === 'Yes' ? true : false);
      form.append("IsDraft", data.IsDraft);
      form.append("FormApprovers", JSON.stringify(data.form_approval));
      form.append("IsHumanRight", select.human_right.value);

      if (!!data.removedDocumentIds.length) {
          for (let i = 0; i < data.removedDocumentIds.length; i++) {
              form.append("RemovedDocumentIds", data.removedDocumentIds[i]);
          }
      }

      // processing-attachment-area
      for (let i = 0; i < data.uploadedFiles.length; i++) {
        if (data.uploadedFilesAttachType[i] === "Signed RTA by Duet")
        form.append("SignedRtaByDuet", data.uploadedFiles[i]);

        if (data.uploadedFilesAttachType[i] === "Draft Contract/PO to be reviewed by Signatories")
        form.append("DraftContractPoToBeReviewedBySignatories", data.uploadedFiles[i]);

        if (data.uploadedFilesAttachType[i] === "Draft PO")
          form.append("DraftPO", data.uploadedFiles[i]);

        else if (data.uploadedFilesAttachType[i] === "Bid/Analysis Waiver")
          form.append("BidAnalysisWaiver", data.uploadedFiles[i]);

          else if (data.uploadedFilesAttachType[i] === "Bidders Lists")
            form.append("BiddersLists", data.uploadedFiles[i]);

        else if (data.uploadedFilesAttachType[i] === "Vendors Quotation")
          form.append("VendorsQuotation", data.uploadedFiles[i]);

          else if (data.uploadedFilesAttachType[i] === "Proof Of OEM Representation")
            form.append("ProofOfOEMRepresentation", data.uploadedFiles[i]);

            else if (data.uploadedFilesAttachType[i] === "Transaction Related Document")
              form.append("TransactionRelatedDocument", data.uploadedFiles[i]);

        else {
          form.append("Others", data.uploadedFiles[i]);
          others_description = data.uploadedFilesAttachType[i];
        }
    }

      form.append("OthersDescription", others_description);

      return await makePUT(`RoutingSlipForMaterialPurchaseForm/Initiator/Update/${data.formIDQuery}`, form)
      .then((res) => {
        tinyAlert("success", res.data.message);
        return res.data.data;
      })

    } 
    catch (error:any) {

      const allKeysRequired = !!error.response.data.errors ? Object.keys(error.response.data.errors).join(', ') : error.response.data.Message;
      tinyAlert("error", 'Complete all required field: '+ JSON.stringify(allKeysRequired) );
      
    }
  }

  async submitDraft(id: any): Promise<any> {
    try{
      const data = {
        "RoutingSlipForMaterialPurchaseFormId": id
      }
      return await makeREQUEST("PUT", 'RoutingSlipForMaterialPurchaseForm/Draft', data)
      .then((res) => {
        tinyAlert("success", res.data.message);
        return res.data.data;
      })

    } 
    catch (error:any) {

      const allKeysRequired = !!error.response.data.errors ? Object.keys(error.response.data.errors).join(', ') : error.response.data.Message;
      tinyAlert("error", 'Complete all required field: '+ JSON.stringify(allKeysRequired) );
      
    }
  }

  async reviewForm(data: any): Promise<any> {
    try{
      const form_approval_emails = data.form_approval.every((obj:any) => obj.email.trim() !== '');
      if (!form_approval_emails) {
          tinyAlert('error','Some or all emails in approval sequence are empty.');
          return;
      }

      const form: any = new FormData();
      const input = data.formInputs;
      const select = data.formSelects;
      let others_description = "Others";

      
      if(input.PONumber && input.PONumber.length < 10){
        tinyAlert('error','Contract Number must be equal to 10 numbers');
        return;
      }

      if(input.PRNumber && input.PRNumber.length < 8){
        tinyAlert('error','PR Number must be equal to 8 numbers');
        return;
      }

      
      form.append("PreviousRoutingSlipForMaterialPurchaseFormId", data.parentFormId);
      form.append("BriefDescriptionOfMaterials", input.BriefDescriptionOfMaterials);
      form.append("PRNumber", input.PRNumber);
      form.append("Supplier", input.Supplier);
      form.append("Requisitioner", input.Requisitioner);
      form.append("POAmount", input.POAmount);
      form.append("PONumber", input.PONumber);
      form.append("DeliveryPlace", input.DeliveryPlace);
      form.append("POLocalContentValue", input.POLocalContentValue);
      form.append("POLocalContentCalculationMethod", input.POLocalContentCalculationMethod);
      form.append("POLocalContent", input.POLocalContent);
      form.append("SavingsValueUSD", Number(input.SavingsValueUSD));
      form.append("Addendum", input.Addendum);
      form.append("BuyersComment", input.BuyersComment);
      form.append("NameOfBuyer", input.NameOfBuyer);
      form.append("AlternateEmailAddress", !!input.AlternateEmailAddress ? input.AlternateEmailAddress : '');
      form.append("POCurrency", select.po_currency.value);
      form.append("DepartmentId", select.department.value);
      form.append("DDQStatus", select.ddq_status.value);
      form.append("Materialtype", select.material_type.value);
      form.append("DateOfPRAttribution", formatYYYYMMDD(select.date_of_prattribution));
      form.append("DateOfRFQ", formatYYYYMMDD(select.date_of_rfq));
      form.append("PlannedDelivery", formatYYYYMMDD(select.PlannedDelivery));
      form.append("NUPRCStatus", select.nuprc_status.value);
      form.append("ProcurementMethod", select.procurement_method.value);
      form.append("SavingType", select.saving_type.value);
      form.append("Incoterms", select.incoterms.value);
      form.append("SavingsAmount", Number(input.NewCost) - Number(input.ReferenceCost) );
      form.append("ReferenceCost", input.ReferenceCost);
      form.append("NewCost", input.NewCost);
      form.append("ContractJustification", input.ContractJustification);
      form.append("SavingsJustification", input.SavingsJustification);
      form.append("LessThanThreeBidsInCFT", select.less_three_bids.value === 'Yes' ? true : false);
      form.append("VendorsAggregateCommitmentYTDGreaterThan1M", select.VendorsAggregateCommitmentYTDGreaterThan1M.value === 'Yes' ? true : false);
      form.append("IsDraft", false);
      form.append("FormApprovers", JSON.stringify(data.form_approval));
      form.append("IsHumanRight", select.human_right.value);

      for (let i = 0; i < data.uploadedFiles.length; i++) {
        if (data.uploadedFilesAttachType[i] === "Signed RTA by Duet")
        form.append("SignedRtaByDuet", data.uploadedFiles[i]);

        if (data.uploadedFilesAttachType[i] === "Draft Contract/PO to be reviewed by Signatories")
        form.append("DraftContractPoToBeReviewedBySignatories", data.uploadedFiles[i]);
        
          if (data.uploadedFilesAttachType[i] === "Draft PO")
            form.append("DraftPO", data.uploadedFiles[i]);

          else if (data.uploadedFilesAttachType[i] === "Bid/Analysis Waiver")
            form.append("BidAnalysisWaiver", data.uploadedFiles[i]);

            else if (data.uploadedFilesAttachType[i] === "Bidders Lists")
              form.append("BiddersLists", data.uploadedFiles[i]);

          else if (data.uploadedFilesAttachType[i] === "Vendors Quotation")
            form.append("VendorsQuotation", data.uploadedFiles[i]);

            else if (data.uploadedFilesAttachType[i] === "Proof Of OEM Representation")
              form.append("ProofOfOEMRepresentation", data.uploadedFiles[i]);

              else if (data.uploadedFilesAttachType[i] === "Transaction Related Document")
                form.append("TransactionRelatedDocument", data.uploadedFiles[i]);

          else {
            form.append("Others", data.uploadedFiles[i]);
            others_description = data.uploadedFilesAttachType[i];
          }
      }

      form.append("OthersDescription", others_description);

      return await makePOST('RoutingSlipForMaterialPurchaseForm/Review', form)
      .then((res) => {
        tinyAlert("success", res.data.message);
        return res.data.data;
      })

    } 
    catch (error:any) {

      const allKeysRequired = !!error.response.data.errors ? Object.keys(error.response.data.errors).join(', ') : error.response.data.Message;
      tinyAlert("error", 'Complete all required field: '+ JSON.stringify(allKeysRequired) );
      
    }
  }

  async fetchFormById(id: string): Promise<any> {
    try{
      return await makeREQUEST("GET", "RoutingSlipForMaterialPurchaseForm/" + id)
      .then((res) => {
        return res.data.data;

      })

    } catch (error) {
      tinyAlert("error", 'there is an error');
      console.error('Error:', error);
    }
  }

  async fetchApprovalSequence(data: any): Promise<any> {
    try{
      return await makeREQUEST("GET", `RoutingSlipForMaterialPurchaseForm/GetApprovalSequence?ContractValue=${data.ContractValue}&Currency=${data.Currency}&DepartmentId=${data.DepartmentId}&LessThanThreeBidsInCFT=${data.LessThanThreeBidsInCFT}`)
      .then((res) => {
        tinyAlert("success", "approval sequence updated!");
        return res.data.data;
      })
    } catch (error) {
      tinyAlert("error", printError(error));
      console.error('Error:', error);
    }
  }

  async deleteForm(id: string): Promise<any> {
    try{
      return await makeREQUEST("DELETE", "RoutingSlipForMaterialPurchaseForm/" + id)
      .then((res) => {
        tinyAlert("success", res.data.message);
        return res.data.data;

      })

    } catch (error) {
      tinyAlert("error", 'there is an error');
      console.error('Error:', error);
    }
  }


};

const rsmp = new RoutingSlipForMaterialPurchaseFormService();
export default rsmp;